<template>
  <section class="news" v-if="posts.length > 0">
    <div class="news__container">
      <div class="news__header">
        <h1 class="h1" data-aos="zoom-y-out" data-aos-delay="400">
          {{ t("news.titleTop") }}
        </h1>
        <h1 class="h1" data-aos="zoom-y-out" data-aos-delay="400">
          {{ t("news.titleBottom") }}
        </h1>
        <hr data-aos="zoom-y-out" data-aos-delay="400" />
        <h2 class="h2" data-aos="zoom-y-out" data-aos-delay="400">
          {{ t("news.subTitle") }}
        </h2>
      </div>
      <div class="posts__layer--container">
        <div class="posts__container">
          <div
            class="post"
            v-for="post in posts"
            :key="post.id"
            data-aos="zoom-y-out"
            data-aos-delay="400"
          >
            <div class="image__wrapper">
              <img
                v-if="post.attachments.data[0].media"
                class="unselectable lazyload"
                :data-src="post.attachments.data[0].media.image.src"
                width="450"
                height="300"
                alt="fb image"
              />
            </div>
            <div class="post__details">
              <div class="post__content">
                <p>
                  {{ new Date(post.created_time).toLocaleString() }}
                </p>
                <hr />
                <p>{{ post.message.substring(0, 100) }} (...)</p>
              </div>
              <a
                :href="`https://www.facebook.com/${post.id}`"
                target="_blank"
                rel="noopener"
              >
                <button type="button">{{ t("news.button") }}</button></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { defineComponent, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

interface Post {
  created_time: string;
  id: string;
  message: string;
  attachments: {
    data: {
      media: {
        image: {
          height: number;
          src: string;
          width: number;
        };
      };
    }[];
  };
}

interface FacebookResponse {
  data: Post[];
}

const messages = {
  pl: {
    news: {
      titleTop: "Bądź z nami na bieżąco,",
      titleBottom: "Sprawdź co u nas",
      subTitle: "Ostatnie posty na naszym facebooku",
      button: "Zobacz",
    },
  },
  de: {
    news: {
      titleTop: "Bleiben Sie regelmäßig bei uns,",
      titleBottom: "Schau mal was bei uns los ist",
      subTitle: "Neueste Beiträge auf unserem Facebook",
      button: "Suchen",
    },
  },
  en: {
    news: {
      titleTop: "Keep in touch with us,",
      titleBottom: "check our social media",
      subTitle: "Recent facebook posts",
      button: "Search",
    },
  },
};

export default defineComponent({
  name: "News",
  setup() {
    const { t } = useI18n({
      messages,
    });
    let posts: Ref<Post[]> = ref([]);

    /* eslint-disable @typescript-eslint/no-explicit-any */
    (window as any).fbAsyncInit = () => {
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      /* eslint-disable no-undef */
      // @ts-ignore
      FB.init({
        appId: process.env.VUE_APP_fbId,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v10.0",
      });
      // @ts-ignore
      FB.api(
        `/${process.env.VUE_APP_fbId}/feed?fields=message,created_time,attachments`,
        "GET",
        {
          access_token: process.env.VUE_APP_at,
        },
        (response: FacebookResponse) => {
          const retrievedPosts = response.data.filter((post) => post.message);
          posts.value = retrievedPosts.slice(0, 3);
        }
      );
    };

    return { t, posts };
  },
});
</script>
<style lang="scss">
.news {
  z-index: 1;
  @apply w-full relative;
  .news__container {
    .news__header {
      min-height: 60vh;
      @apply relative;
      @media (min-height: 768px) {
        min-height: 50vh;
      }
      @media (min-width: 768px) and (min-height: 1024px) {
        min-height: 40vh;
      }
      @apply bg-brown py-2xl md:px-xl lg:px-3xl flex items-center flex-col text-center;
      h1 {
        @apply text-xl lg:text-4xl;
      }
      hr {
        @apply mt-8;
      }
      h2 {
        @apply p-sm ssm:p-none text-base mt-4 text-black uppercase font-normal;
      }

      &:before {
        content: "";
        transform: rotate(45deg);
        @apply w-6 h-6 bg-gray absolute -top-3;
      }
    }
    .posts__layer--container {
      min-height: 80vh;
      @screen ssm {
        min-height: 50vh;
      }
      // @media (min-width: 768px) and (min-height: 768px) {
      //   min-height: 50vh;
      // }

      @media (min-width: 768px) and (min-height: 1024px) {
        min-height: 35vh;
      }

      @media (min-width: 1280px) and (min-height: 1024px) {
        min-height: 50vh;
      }

      @apply bg-gray relative flex justify-center items-center flex-col;
      .posts__container {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: auto;
        @screen md {
          grid-template-columns: repeat(3, 1fr);
        }
        @apply py-2xl ssm:py-sm px-lg md:px-xl lg:px-3xl justify-center items-center absolute -top-40 gap-x-10;
        .post {
          @apply max-w-md py-md h-full;
          &:not(:first-of-type) {
            @apply hidden;
          }
          @screen md {
            &:not(:first-of-type) {
              @apply grid;
            }
          }
          display: grid;
          // grid-template-rows: 40% 0.7fr;
          grid-template-rows: 50% 1fr;
          align-items: stretch;

          .image__wrapper {
            @apply w-full h-full;
            img {
              max-height: 30vh;
              @apply w-full h-full object-cover;
            }
          }

          .post__details {
            @apply py-lg flex justify-between items-center flex-col text-black;
            .post__content {
              @apply flex justify-center md:justify-between items-center flex-col;

              p {
                @apply text-center md:text-left;
                &:first-of-type {
                  @apply font-bold;
                }
                &:last-of-type {
                  @apply mb-8 lg:mb-4;
                }
              }
              hr {
                @apply bg-brown my-4;
              }
            }
            button {
              @apply text-black border-brown hover:text-white hover:bg-brown hover:border-brown;
            }
          }
        }
      }
    }
  }
}
</style>
