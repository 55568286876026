
import { defineComponent, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

interface Post {
  created_time: string;
  id: string;
  message: string;
  attachments: {
    data: {
      media: {
        image: {
          height: number;
          src: string;
          width: number;
        };
      };
    }[];
  };
}

interface FacebookResponse {
  data: Post[];
}

const messages = {
  pl: {
    news: {
      titleTop: "Bądź z nami na bieżąco,",
      titleBottom: "Sprawdź co u nas",
      subTitle: "Ostatnie posty na naszym facebooku",
      button: "Zobacz",
    },
  },
  de: {
    news: {
      titleTop: "Bleiben Sie regelmäßig bei uns,",
      titleBottom: "Schau mal was bei uns los ist",
      subTitle: "Neueste Beiträge auf unserem Facebook",
      button: "Suchen",
    },
  },
  en: {
    news: {
      titleTop: "Keep in touch with us,",
      titleBottom: "check our social media",
      subTitle: "Recent facebook posts",
      button: "Search",
    },
  },
};

export default defineComponent({
  name: "News",
  setup() {
    const { t } = useI18n({
      messages,
    });
    let posts: Ref<Post[]> = ref([]);

    /* eslint-disable @typescript-eslint/no-explicit-any */
    (window as any).fbAsyncInit = () => {
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      /* eslint-disable no-undef */
      // @ts-ignore
      FB.init({
        appId: process.env.VUE_APP_fbId,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v10.0",
      });
      // @ts-ignore
      FB.api(
        `/${process.env.VUE_APP_fbId}/feed?fields=message,created_time,attachments`,
        "GET",
        {
          access_token: process.env.VUE_APP_at,
        },
        (response: FacebookResponse) => {
          const retrievedPosts = response.data.filter((post) => post.message);
          posts.value = retrievedPosts.slice(0, 3);
        }
      );
    };

    return { t, posts };
  },
});
